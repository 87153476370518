var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "tray" } },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isModalOpen,
                  expression: "isModalOpen"
                }
              ],
              staticClass: "modal-card__overlay",
              attrs: { tag: "div", to: "../" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-card__block",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return function() {
                        return null
                      }.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-card__slider",
                      style: [_vm.screenWidth < 480 ? _vm.imageWidth : ""]
                    },
                    [
                      _c("div", { staticClass: "modal-card__container-img" }, [
                        _vm.screenWidth > 480
                          ? _c(
                              "div",
                              { staticClass: "modal-card__slider--lg" },
                              [
                                _c("div", { staticClass: "modal-card__img" }, [
                                  _c("img", {
                                    staticClass: "modal-card__image",
                                    attrs: {
                                      src: _vm._f("removePathString")(
                                        _vm.pathImg + _vm.mainImg
                                      ),
                                      alt: _vm.productTitle
                                    }
                                  })
                                ])
                              ]
                            )
                          : _c(
                              "div",
                              {
                                ref: "slider",
                                staticClass: "modal-card__slider--lg"
                              },
                              _vm._l(_vm.productImages, function(image, index) {
                                return _c(
                                  "div",
                                  {
                                    key: image,
                                    staticClass: "modal-card__img",
                                    style: _vm.imagePosition
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "modal-card__image",
                                      class: {
                                        visibility:
                                          _vm.currentImageIndex == index
                                      },
                                      style: [
                                        _vm.screenWidth < 480
                                          ? _vm.imageHeight
                                          : ""
                                      ],
                                      attrs: {
                                        src: _vm._f("removePathString")(
                                          _vm.pathImg + image
                                        ),
                                        alt: _vm.productTitle
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-card__carousel" }, [
                        _vm.productImages.length > 4
                          ? _c("button", {
                              staticClass:
                                "modal-card__btn--sm modal-card__btn-prev--sm",
                              on: { click: _vm.prevSlide }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-card__changer" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-card__row",
                              style: {
                                "margin-left":
                                  "-" + 25 * _vm.currentSlideIndex + "%"
                              }
                            },
                            _vm._l(_vm.productImages, function(image, index) {
                              return _c("div", {
                                key: image,
                                staticClass: "modal-card__item",
                                class: {
                                  visible: _vm.activeImageIndex == index
                                },
                                attrs: {
                                  "data-image": _vm._f("removePathString")(
                                    _vm.pathImg + image
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeImage(index)
                                  }
                                }
                              })
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _vm.productImages.length > 4
                          ? _c("button", {
                              staticClass:
                                "modal-card__btn--sm modal-card__btn-next--sm",
                              on: { click: _vm.nextSlide }
                            })
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "modal-card__close-btn",
                      attrs: { tag: "div", to: "../" }
                    },
                    [
                      _c("div", { staticClass: "modal-card__close-btn--line" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-card__close-btn--line" })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "modal-card__down-btn",
                      attrs: { tag: "div", to: "../" }
                    },
                    [
                      _c("div", { staticClass: "modal-card__down-btn--icon" }, [
                        _vm._v("\n            ❯\n          ")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-card__swipe-line" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-card__body" }, [
                    _c("div", { staticClass: "modal-card__content" }, [
                      _c(
                        "div",
                        { staticClass: "modal-description" },
                        [
                          _c(
                            "div",
                            { staticClass: "product-modal-card" },
                            [
                              _c(
                                "h2",
                                { staticClass: "product-modal-card__title" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        this.isUsingShopLogic
                                          ? _vm.productTitle
                                          : _vm.productTitle +
                                              " - " +
                                              (_vm.productVariants[
                                                _vm.activePriceIndex
                                              ].price +
                                                _vm.productPriceModifiers) +
                                              " ₽"
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "product-modal-card__desc" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.productBody ||
                                          _vm.productDescription
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                Object.keys(_vm.productVariantsList),
                                function(key) {
                                  return _vm.productVariants.length > 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "product-modal-card__parameter"
                                        },
                                        _vm._l(
                                          _vm.productVariantsList[key],
                                          function(variant, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "radio-product"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "radio-product__toggle",
                                                    class: {
                                                      toggle:
                                                        _vm
                                                          .selectedParametersIndexMap[
                                                          key
                                                        ] === index
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "radio-product__input",
                                                      attrs: { type: "radio" },
                                                      domProps: {
                                                        value: variant
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "radio-product__label",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.activateToggleProduct(
                                                              key,
                                                              index,
                                                              _vm.productId,
                                                              variant.amount
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(_vm._s(variant))]
                                                    ),
                                                    _c("br")
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                }
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.productVariants[_vm.activePriceIndex]
                              .groupModifiers,
                            function(groupModifier) {
                              return _vm.productVariants[_vm.activePriceIndex]
                                .groupModifiers !== undefined
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "product-modal-card__modifiers",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.selectGroupModifier(
                                            groupModifier
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          class: {
                                            active:
                                              _vm.selectedGroupModifier ===
                                              groupModifier,
                                            group:
                                              _vm.selectedGroupModifier ===
                                              groupModifier
                                          }
                                        },
                                        [_vm._v(_vm._s(groupModifier.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "modifiers",
                                          class: {
                                            visible:
                                              _vm.selectedGroupModifier ===
                                              groupModifier
                                          }
                                        },
                                        _vm._l(
                                          groupModifier.modifiers,
                                          function(modifier) {
                                            var _obj
                                            return _c(
                                              "div",
                                              {
                                                key: modifier.id,
                                                staticClass: "modifier",
                                                class:
                                                  ((_obj = {
                                                    active: _vm.selectedModifiers.find(
                                                      function(gm) {
                                                        return (
                                                          gm.id === modifier.id
                                                        )
                                                      }
                                                    )
                                                  }),
                                                  (_obj[
                                                    _vm.selectedGroupModifier && [
                                                      modifier.price === 0
                                                        ? "check"
                                                        : "plus"
                                                    ]
                                                  ] = _vm.selectedModifiers.find(
                                                    function(gm) {
                                                      return (
                                                        gm.id === modifier.id
                                                      )
                                                    }
                                                  )),
                                                  _obj),
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.selectModifier(
                                                      modifier
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _c("div", {
                                                    staticClass:
                                                      "modifier-checker"
                                                  }),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        modifier.productName
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      modifier.price === 0
                                                        ? ""
                                                        : modifier.price + "₽"
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                : _vm._e()
                            }
                          ),
                          _vm._v(" "),
                          this.isUsingShopLogic
                            ? _c("div", { staticClass: "modal__button" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "product-modal-card__controle"
                                  },
                                  [
                                    _vm.productBuyable &&
                                    _vm.amountProduct !== 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "product-modal-card__btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.addProduct(
                                                  _vm.productId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Добавить в корзину за " +
                                                _vm._s(
                                                  _vm.productVariants[
                                                    _vm.activePriceIndex
                                                  ].price +
                                                    _vm.productPriceModifiers
                                                ) +
                                                " ₽\n                  "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass:
                                              "product-modal-card__btn product-modal-card__btn--disabled"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Нет в наличии\n                  "
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  this.isUsingShopLogic
                    ? _c("div", { staticClass: "modal__button--mobile" }, [
                        _c(
                          "div",
                          { staticClass: "product-modal-card__controle" },
                          [
                            _vm.productBuyable && _vm.amountProduct !== 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "product-modal-card__btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.addProduct(_vm.productId)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              В корзину " +
                                        _vm._s(
                                          _vm.productVariants[
                                            _vm.activePriceIndex
                                          ].price + _vm.productPriceModifiers
                                        ) +
                                        " ₽\n            "
                                    )
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "product-modal-card__btn product-modal-card__btn--disabled"
                                  },
                                  [
                                    _vm._v(
                                      "\n              Нет в наличии\n            "
                                    )
                                  ]
                                )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d92e70ca", { render: render, staticRenderFns: staticRenderFns })
  }
}