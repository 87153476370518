<template>
  <div>
    <transition name="tray">
      <router-link tag="div" to="../" class="modal-card__overlay" v-show="isModalOpen">
        <div class="modal-card__block" v-on:click.stop="() => null">
          <div class="modal-card__slider" :style="[screenWidth < 480 ? imageWidth : '']">
            <div class="modal-card__container-img">
              <div v-if="screenWidth > 480" class="modal-card__slider--lg">
                <div class="modal-card__img">
                  <img class="modal-card__image" :src="pathImg + mainImg | removePathString" :alt="productTitle"/>
                </div>
              </div>
              <div v-else ref="slider" class="modal-card__slider--lg">
                <div class="modal-card__img" v-for="(image, index) in productImages" :key="image" :style="imagePosition">
                  <img class="modal-card__image" :style="[screenWidth < 480 ? imageHeight : '']" :class="{visibility: currentImageIndex == index}" :src="pathImg + image | removePathString" :alt="productTitle"/>
                </div>
              </div>
            </div>
            <div class="modal-card__carousel">
              <button v-if="productImages.length > 4" class="modal-card__btn--sm modal-card__btn-prev--sm" @click="prevSlide"></button>
              <div class="modal-card__changer">
                <div class="modal-card__row" :style="{'margin-left': '-' + (25 * currentSlideIndex) + '%'}">
                  <div class="modal-card__item" v-for="(image, index) in productImages" :key="image" :data-image="pathImg + image | removePathString" @click="changeImage(index)" :class="{visible: activeImageIndex == index}">
                  </div>
                </div>
              </div>
              <button v-if="productImages.length > 4" class="modal-card__btn--sm modal-card__btn-next--sm" @click="nextSlide"></button>
            </div>
          </div>
          <router-link tag="div" to="../" class="modal-card__close-btn">
            <div class="modal-card__close-btn--line"></div>
            <div class="modal-card__close-btn--line"></div>
          </router-link>
          <router-link tag="div" to="../" class="modal-card__down-btn">
            <div class="modal-card__down-btn--icon">
              &#10095;
            </div>
          </router-link>
          <div class="modal-card__swipe-line"></div>
          <div class="modal-card__body">
            <div class="modal-card__content">
              <div class="modal-description">
                <div class="product-modal-card">
                  <h2 class="product-modal-card__title">
                    {{ this.isUsingShopLogic ? productTitle : productTitle + ' - ' + (productVariants[activePriceIndex].price + productPriceModifiers) + ' ₽'}}
                  </h2>
                  <p class="product-modal-card__desc">
                    {{ productBody || productDescription }}
                  </p>
                  <div class="product-modal-card__parameter" v-if="productVariants.length > 1" v-for="(key) in Object.keys(productVariantsList)">
                    <div class="radio-product" v-for="(variant,index) in productVariantsList[key]" :key="index">
                      <div class="radio-product__toggle" :class="{toggle: selectedParametersIndexMap[key] === index}">
                        <input class="radio-product__input" type="radio" :value="variant">
                        <label class="radio-product__label" @click="activateToggleProduct(key, index, productId, variant.amount)">{{ variant }}</label><br>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-modal-card__modifiers"
                     v-if="productVariants[activePriceIndex].groupModifiers !== undefined"
                     v-for="groupModifier in productVariants[activePriceIndex].groupModifiers" @click.stop="selectGroupModifier(groupModifier)">
                  <h4 :class="{active: selectedGroupModifier === groupModifier, group: selectedGroupModifier === groupModifier}">{{ groupModifier.title }}</h4>
                  <div class="modifiers" :class="{visible: selectedGroupModifier === groupModifier}">
                    <div class="modifier" v-for="modifier in groupModifier.modifiers" :key="modifier.id"
                         :class="{active: selectedModifiers.find(gm => gm.id === modifier.id),
                           [selectedGroupModifier && [modifier.price === 0 ? 'check' : 'plus']]:selectedModifiers.find(gm => gm.id === modifier.id)}" @click.stop="selectModifier(modifier)">
                      <span>
                        <div class="modifier-checker"></div>
                        {{ modifier.productName }}
                      </span>
                      <span>{{ modifier.price === 0 ? '' : (modifier.price + '₽') }}</span>
                    </div>
                  </div>
                </div>
                <div class="modal__button" v-if="this.isUsingShopLogic">
                  <div class="product-modal-card__controle">
                    <button @click="addProduct(productId)" class="product-modal-card__btn" v-if="productBuyable && (amountProduct !== 0)">
                      Добавить в корзину за {{ productVariants[activePriceIndex].price + productPriceModifiers }} ₽
                    </button>
                    <button v-else class="product-modal-card__btn product-modal-card__btn--disabled">
                      Нет в наличии
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__button--mobile" v-if="this.isUsingShopLogic">
            <div class="product-modal-card__controle">
              <button v-if="productBuyable && (amountProduct !== 0)" class="product-modal-card__btn" @click="addProduct(productId)">
                В корзину {{ (productVariants[activePriceIndex].price + productPriceModifiers) }} ₽
              </button>
              <button v-else class="product-modal-card__btn product-modal-card__btn--disabled">
                Нет в наличии
              </button>
            </div>
          </div>
        </div>
      </router-link>
    </transition>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import * as mutations from '../store/types/mutations-types'
import * as actions from '../store/types/actions-types'
import store from '../store'
import router from '../router'

export default {
  store,
  router,
  name: 'child-modal',
  data:() => {
    return {
      currentSlideIndex: 0,
      image: [],
      touch: {
        startX: 0,
        endX: 0,
        startY: 0,
        endY: 0,
      },
    }
  },
  computed: {
    ...mapState({
      isModalOpen: state => state.isModalOpen,
      selectedParametersIndexMap: state => state.selectedParametersIndexMap,
      activeImageIndex: state => state.activeImageIndex,
      activePriceIndex: state => state.activePriceIndex,
      pathImg: state => state.pathImg,
      mainImg: state => state.mainImg,
      productId: state => state.product.id,
      productTitle: state => state.product.title,
      productBody: state => state.product.body,
      productDescription: state => state.product.description,
      productPrice: state => state.product.price,
      productPriceModifiers: state => state.productPriceModifiers,
      productImages: state => state.product.images,
      productBuyable: state => state.product.isBuyable,
      productVariants: state => state.product.variants,
      productVariantsList: state => state.productVariantsList,
      modifiers: state => state.modifiers,
      selectedModifiers: state => state.selectedModifiers,
      selectedGroupModifier: state => state.selectedGroupModifier,
      amountProduct: state => state.amountProduct,
      currentImageIndex: state => state.currentImageIndex,
      screenWidth: state => state.screenWidth,
    }),

    imageWidth() {
      return {
        width: this.productImages.length * 100 + '%'
      }
    },

    imageHeight() {
      return {
        height: this.screenWidth - 90 + 'px'
      }
    },

    imagePosition() {
      return {
        transform: 'translateX(-'+ this.currentImageIndex * 100 +'%)'
      }
    },
  },
  methods: {
    ...mapMutations({
      setActiveToggle: mutations.SET_ACTIVE_TOGGLE,
      setActiveImage: mutations.SET_ACTIVE_IMAGE,
      setAmount: mutations.SET_AMOUNT,
      setModifiers: mutations.SET_MODIFIERS,
      selectModifier: mutations.SELECT_MODIFIER,
      selectGroupModifier: mutations.SELECT_GROUP_MODIFIER,
      changeToggleParameter: mutations.CHANGE_TOGGLE_PARAMETER,
    }),

    activateToggleProduct(keyOfVariant, indexOfVariantInList, productId, amount) {
      this.setActiveToggle([keyOfVariant, indexOfVariantInList])
      this.changeToggleParameter(productId)
      this.setAmount(amount)
      this.$store.commit(mutations.RESET_SELECTED_MODIFIERS)
    },

    addProduct(productId) {
      store.dispatch(actions.ADD_PRODUCT, productId)
      this.$store.commit(mutations.RESET_SELECTED_MODIFIERS)
    },

    prevSlide() {
      if( this.currentSlideIndex > 0 ) {
        this.currentSlideIndex--
      }
    },

    nextSlide() {
      if( this.currentSlideIndex < this.productImages.length - 4)
        this.currentSlideIndex++
    },

    changeImage(index) {
      this.setActiveImage(index)
    },

    touchstart(event) {
      this.touch.startX = event.touches[0].clientX;
      this.touch.endX = 0;
      this.touch.startY = event.touches[0].clientY;
      this.touch.endY = 0;
    },

    touchmove(event) {
      this.touch.endX = event.touches[0].clientX;
      this.touch.endY = event.touches[0].clientY;
    },

    touchend(event) {
      if(!this.touch.endX || Math.abs(this.touch.endX - this.touch.startX) < 20)
        return;

      if(this.touch.endX < this.touch.startX)
        this.$store.commit(mutations.ACTIVATE_NEXT_IMAGE)
      else
        this.$store.commit(mutations.ACTIVATE_PREV_IMAGE)

      if(!this.touch.endY || Math.abs(this.touch.endY - this.touch.startY) < 150)
        return;

      if(this.touch.endY > this.touch.startY)
        this.$router.push('../')
    },
    handleKeyup(event) {
      if (event.keyCode === 27) {
        this.$router.push('../')
      }
    }
  },
  filters: {
    removePathString(value) {
      return value.replace('IMAGE_PATH', '')
    }
  },
  created: function() {
    document.addEventListener('keyup', this.handleKeyup)
  },

  mounted() {
    this.$refs.slider.addEventListener('touchstart', event => this.touchstart(event));
    this.$refs.slider.addEventListener('touchmove', event => this.touchmove(event));
    this.$refs.slider.addEventListener('touchend', () => this.touchend());
  },

  destroyed: function() {
    document.removeEventListener('keyup', this.handleKeyup)
  }
};
</script>

<style>
@import '../../../assets/css/variables.css';

.active {
  opacity: 1 !important;
}

.active::before {
  position: absolute;
  left: 6px;
  font-size: 20px;
}

.check::before{
  content: '✓';
  color: #F1F1F1;
}

.group::after{
  /*margin-left: 10px;*/
  /*content: '✓';*/
}

.plus::before{
  content: '✓';
  color: #F1F1F1;
}

.plus .modifier-checker, .check .modifier-checker {
  background: #EF4618;
  border: 1px solid #EF4618;
}

  @media (min-width: 768px) {
    .tray-enter, .tray-leave-to {
      opacity: 0;
    }

    .tray-leave, .tray-enter-to {
      opacity: 1;
    }

    .tray-enter-active, .tray-leave-active {
      transition: opacity 200ms;
    }
}

  @media (max-width: 768px) {
    .tray-enter-active {
      animation: tray-in .5s;
    }

    .tray-leave-active {
      animation: tray-in .5s reverse;
    }

  @keyframes tray-in {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0%);
    }
  }
}

.modal-card__overlay {
  position: fixed;
  top: 0;left: 0;right: 0;bottom: 0;
  background: rgba(51, 51, 51, 0.5);
  z-index: 25;
  overflow: auto;
  -webkit-filter: blur(0px); /* chrome blur bag in popup's with overflow */

  @media (max-width: 768px) {
    top: 70px;
  }
}

.modal-card__block {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  padding: 25px;
  transform: translate(-50%, -50%);
  height: auto;
  border-radius: 40px;
  z-index: 100;
  background: #F2F2F2;

  @media (max-width: 768px) {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95%;
    min-height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    margin: 10px auto 30px;
    padding: 0;
  }

  @media (max-width: 391px) {
    border-radius: 20px;
  }
}

.product-modal-card__modifiers h4 {
  margin: 30px 0 20px 34px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

/*.product-modal-card__modifiers h4:hover {*/
/*  color: var(--modal-card-action-primary-color);*/
/*}*/

.visible{
  display: flex!important;
  flex-direction: column;
}

.modifiers {
  display: none;
  cursor: pointer;
  height: 170px;
  overflow-y: scroll;
}

.modifier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 0;
}

.modifier:not(:last-child) {
  margin-bottom: 10px;
}

.modifier span{
  display: flex;
  align-items: center;
}

.modifier-checker {
  background: #F2F2F2;
  border: 1px solid #8F8C8C;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.modal-card__close-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -5px;
  right: -53px;
  cursor: pointer;
  z-index: 100;

  @media (max-width: 480px) {
    display: none
  }
}

.modal-card__close-btn--line {
  width: 100%;
  height: 4px;
  background-color: #F2F2F2;
}

.modal-card__close-btn--line:first-of-type {
  transform: rotate(45deg) translate(12px, 12px);
}

.modal-card__close-btn--line:last-of-type {
  transform: rotate(-45deg) translate(-9px, 9px);
}

.modal-card__down-btn {
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  z-index: 100;
  background: #EBE6E6;
  transform: rotate(90deg);
  border-radius: 50%;

  @media (max-width: 391px) {
    display: flex;
    justify-content: center;
  }
}

.modal-card__down-btn--icon {
  color: #333333;
  font-size: 15px;
  transform: translateY(-2px);
}

.modal-card__swipe-line {

  @media (max-width: 768px) {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.modal-card__swipe-line::before {

  @media (max-width: 768px) {
    content: '';
    position: absolute;
    margin-right: -50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50px;
    height: 4px;
    border-radius: 3px;
    background: color(var(--modal-card-action-primary-color) lightness(40%));
  }
}

.modal-card__body {
  padding: 0 30px;

  @media (max-width: 480px) {
    padding: 30px 20px;
    overflow: hidden;
  }
}

.modal-card__content {
  display: flex;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    align-items: center;
  }
}

.modal-card__container-img {
  position: relative;
}
.modal-card__slider {
  position: relative;
  height: 490px;

  @media (max-width: 480px) {
    height: 100%;
  }
}

.modal-card__slider--lg {
  display: flex;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.modal-card__btn-next--lg, .modal-card__btn-prev--lg {
  display: none;
}

.modal-card__img {
  width: 490px;
  height: 490px;
  border-radius: 25px;
  overflow: hidden;

  @media (max-width: 480px) {
    width: 100%;
    transition: all ease .5s;
    padding: 0;
    border-radius: 20px 20px 0 0;
    height: 100%;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1200px 0
  }
  100% {
    background-position: 1200px 0
  }
}

.modal-card__image {
  width: 100%;
  object-fit: cover;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #e2e2e2 8%, #f2f2f2 40%, #e2e2e2 100%);
  background-size: 1200px 104px;

  @media (min-width: 480px) {
    height: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
}

.modal-card__carousel {
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  margin-top: 10px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 480px) {
    display: none;
  }
}

.modal-card__btn-next--sm, .modal-card__btn-prev--sm {
  display: block;
  position: absolute;
  height: 30px;
  padding: 10px;
}

.modal-card__btn-next--sm:focus, .modal-card__btn-prev--sm:focus {
  outline: none;
}

.modal-card__btn-next--sm:hover::before, .modal-card__btn-prev--sm:hover::after,
.modal-card__btn-prev--sm:hover::before, .modal-card__btn-next--sm:hover::after {
  background:var(--modal-card-bg-color);/*#b9bcc4 */
}

.modal-card__btn-next--sm::before, .modal-card__btn-prev--sm::after,
.modal-card__btn-prev--sm::before, .modal-card__btn-next--sm::after {
  transition: 0.3s ease-out;
}

.modal-card__btn-prev--sm {
  left: -25px;
}

.modal-card__btn-prev--sm::before {
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  right: 10px;
  width: 3px;
  height: 20px;
  background-color: color(var(--modal-card-action-primary-color) lightness(40%));
  transform: rotate(-45deg);
}

.modal-card__btn-prev--sm::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 3px;
  height: 20px;
  background-color: color(var(--modal-card-action-primary-color) lightness(40%));
  transform: rotate(45deg);
  transform-origin: 100% 0%;
}

.modal-card__btn-next--sm {
  right: -25px;
}

.modal-card__btn-next--sm::before {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  right: 7px;
  width: 3px;
  height: 20px;
  background-color: color(var(--modal-card-action-primary-color) lightness(40%));
  transform: rotate(45deg);
}

.modal-card__btn-next--sm::after {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  right: 15px;
  width: 3px;
  height: 20px;
  background-color: color(var(--modal-card-action-primary-color) lightness(40%));
  transform: rotate(-45deg);
  transform-origin: 100% 0%;
}

.modal-card__changer {
  overflow: hidden;
}

.modal-card__row {
  display: flex;
  transition: all ease .5s;
}

.modal-card__item {
  flex: 1 1 0%;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  margin-right: 10px;
  cursor: pointer;
}

.modal-card__item.visible{
  background: #EF4618;
}

.modal-card__item--img {
  border-radius: 7px;
  width: 75px;
  height: 75px;
  opacity: 0.5;
  transition: 0.15s ease-out;

  @media (min-width: 480px) {
    background-color: var(--modal-card-bg-color);
    background-image: var(--image-bg-color);
  }
}

.modal-card__item--img:hover {
  border-radius: 7px;
  width: 75px;
  height: 75px;
  opacity: 1;
}

.visible {
  opacity: 1;
}

.modal-description {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 495px;

  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
}

.product-modal-card {
  display: flex;
  flex-direction: column;
  width: 415px;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.product-modal-card__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  margin: 0;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.product-modal-card__desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin: 30px 0 0 0;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 0;
  }

  @media (max-width: 480px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.product-modal-card__parameter {
  background-color: var(--modal-card-bg-color);
  height: 37px;
  display: flex;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 8px 0px;
}

.radio-product {
  position: relative;
  flex: 1 1 0%;
  text-align: center;
  transition: color 150ms ease 0s;
  cursor: pointer;
  user-select: none;
  color: var(--main-font-color);
  font-size: 12px;
  line-height: 32px;
}

.radio-product__title {
  line-height: 24px;
  font-size: 14px;
}

.radio-product__toggle {
  width: 100%;
  height: 100%;
}

.toggle {
  position: relative;
  height: 100%;
  transition: transform 200ms ease-out 0s, width;
}

.toggle::before {
  content: "";
  position: absolute;
  height: 92%;
  width: 98%;
  margin: 2px;
  display: block;
  background-color: var(--modal-card-bg-color);
  box-shadow: var(--shadow);
  border-radius: 7px;

    &:hover{
      box-shadow: var(--shadow-hover);
    }
}

.radio-product__input {
  display: none;
  position: absolute;
}

.radio-product__label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 100%;
}

.modal-card__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
  color: #333333;
}

.product-modal-card__btn {
  display: inline-block;
  border: none;
  border-radius: 25px;
  padding: 15px 40px;
  outline: none;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background: #EF4618;
  color: #FFFFFF;

  @media (max-width: 786px) {
    width: 100%;
  }
}

.product-modal-card__btn--disabled {
  background: #E0E0E0;
  color: #8B8B8B;
  cursor: not-allowed
}

.product-modal-card__btn:hover {
  background: #F7967D;
  cursor: pointer;
}

.product-modal-card__btn:active {
  background: #9B2708;
}

.product-modal-card__controle {
  display: flex;
  align-items: center;
}

.modal__button {
  margin: auto 0 0 auto;

  @media (max-width: 768px) {
    display: none;
  }
}

.modal__button--mobile {
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: relative;
    bottom: 0;
    width: 90%;
    background: none;
    box-shadow: none;
    box-sizing: border-box;
    padding: 12px 16px;
  }

  @media (max-width: 480px) {
    position: sticky;
    width: 100%;
    background: none;
    box-shadow: none;
    padding: 20px 20px 10px;
  }
}

</style>
